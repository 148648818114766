import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { Title as PageTitle } from 'components/page'
import { Container } from 'components/container'
import { BaseLayout as Layout } from 'components/layout'
import { SEO } from 'components/seo'

const Text = styled.p`
  text-align: center;
  line-height: 1.6;

  a {
    color: ${props => props.theme.colors.text};
  }
`

const NotFoundPage = () => (
  <Layout noBg>
    <SEO title="404" description="Seite nicht gefunden" />
    <Container>
      <PageTitle>Seite nicht gefunden</PageTitle>
      <Text>
        Bitte kehre zur <Link to="/">Hauptseite</Link> zurück oder nutze die
        Navigation um auf eine andere Seite zu wechseln.
      </Text>
    </Container>
  </Layout>
)

export default NotFoundPage
